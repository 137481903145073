@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');


@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/Inter-Black.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Inter-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/Inter-ExtraBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/Inter-ExtraLight.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/Inter-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Inter-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/Inter-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Inter-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/Inter-Thin.ttf') format('ttf');
}

html {
    overflow-x: hidden !important;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: #f8faff !important;
    overflow-x: hidden !important;
}

a {
    text-decoration: none;
}

select option:hover,
select option:focus {
    background: #1A67D1 !important;
    color: white !important;
}

/* Define font-face rules for other styles and weights here */


@media (min-width: 992px) {
    .large-screen {
        display: block;
    }

    .small-screen {
        display: none;
    }

    .card-tops,
    .contents {
        min-height: 704px !important;
    }
}

@media (max-width: 991.98px) {

    /* .mutlii-drop .multiselect-dropdown .dropdown-btn {
        padding: 8px 18px !important;
    } */

    .small-screen {
        display: block;
    }

    .large-screen {
        display: none;
    }

    .bottom-gap {
        margin-bottom: 8px;
    }

    .bottom-gap2 {
        margin-bottom: 16px;
    }

    .back-arrow {
        padding: 5px 9px;
    }

    .top-heading {
        font-size: 18px;
        margin-left: 15px;
    }

    .main-wrapper .panel .panel-heading .panel-title .accordion-toggle .btn.btn-link:hover,
    .main-wrapper .panel .panel-heading .panel-title .accordion-toggle .btn.btn-link:focus {
        background-color: #d8ebff;
    }

    .main-wrapper .panel .panel-heading .panel-title .accordion-toggle .btn.btn-link {
        color: #404040 !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        letter-spacing: 0.25px;
        padding-left: 25px;
        line-height: 26px;
        text-decoration: none !important;
    }


    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-item:last-child .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-item:last-child .nav-link {
        margin-right: 0 !important;
    }
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .multi-select2 .multiselect-dropdown .dropdown-btn {
        font-size: 13px !important;
    }

    .mutlii-drop .multiselect-dropdown .dropdown-btn {
        font-size: 14px !important;
        padding: 8px 18px !important;
    }

    .tours-mobilecard .center-container-tabs .nav-pills .nav-item:last-child .nav-link {
        margin-right: 0 !important;
    }

    .center-container-tabs .nav-pills .nav-link,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-link,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-link {
        padding: 9px 25px 9px 25px !important;
        margin-right: 10px !important;
        font-size: 13px !important;
    }
}

.center-container-tabs .nav-pills .nav-link,
.tours-mobilecard .center-container-tabs .nav-pills .nav-link,
.market-pulse-mobilecard .center-container-tabs .nav-pills .nav-link,
.news-client-mobilecard .center-container-tabs .nav-pills .nav-link {
    padding: 9px 25px 9px 25px !important;
    margin-right: 10px !important;
    font-size: 13px !important;
}


@media (min-width: 320px) and (max-width: 424.98px) {
    .main-wrapper .panel .panel-body.card-block.card-body {
        padding: 10px !important;
    }

    .main-wrapper .panel .panel-heading .panel-title .accordion-toggle .btn.btn-link {
        font-size: 12px !important;
    }

    .multi-select2 .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
        top: -3px !important;
    }

    .mutlii-drop .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
        top: -3px !important;
        /* top: -6px !important; */
    }

}

.main-wrapper .panel .panel-heading {
    background: #d8ebff;
}

.main-wrapper .steps {
    counter-increment: list;
}

.main-wrapper .lines-half .panel-heading .panel-title .accordion-toggle .btn.btn-link:after {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background: #bdbfc3;
    top: 50%;
    left: 21px;
    position: absolute;
}

.main-wrapper .lines-half .panel-heading .panel-title .accordion-toggle .btn.btn-link:before {
    content: "";
    display: block;
    width: 2px;
    height: 28px;
    background: #bdbfc3;
    top: 0;
    left: 21px;
    position: absolute;
}

.main-wrapper .lines .panel-heading .panel-title .accordion-toggle .btn.btn-link:after {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background: #bdbfc3;
    top: 50%;
    left: 21px;
    position: absolute;
}

.main-wrapper .lines .panel-heading .panel-title .accordion-toggle .btn.btn-link:before {
    content: "";
    display: block;
    width: 2px;
    height: 56px;
    background: #bdbfc3;
    top: 0;
    left: 21px;
    position: absolute;
}

.main-wrapper .steps .panel-heading .panel-title .accordion-toggle .btn.btn-link:before {
    content: counter(list);
    position: absolute;
    top: 16px;
    left: 10px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #000000;
    background: #ffffff;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3rem;
    text-align: center;
    z-index: 999;
    border: 1px solid #ffffff;
}

.steps-complete:before {
    content: "\2713";
    background: #0AC246;
    color: white;
}

.steps-active:before {
    content: counter(list);
    position: absolute;
    top: -10.5px;
    left: -13px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #ffffff;
    background: #1F549F;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3rem;
    text-align: center;
    z-index: 99999999;
    border: 1px solid #1F549F;
}

@media (max-width: 575.98px) {
    .main-tabs .nav-tabs .nav-item .nav-link span {
        font-size: .675rem !important;
    }

    .main-tabs .nav-tabs .nav-item .nav-link {
        padding: .4rem !important;
    }

    .main-tabs .subtitle-container .subtitle {
        font-size: .675rem !important;
    }

    .main-tabs .option {
        font-size: 0.9rem !important;
    }

    .center-container-tabs .nav-pills .nav-link,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-link,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-link {
        padding: 8px 10px 8px 10px !important;
        margin-right: 0 !important;
        font-size: 12px !important;
    }

    .center-container-tabs .custom-tab-heading i {
        font-size: 16px !important;
    }

    .small-mt {
        margin-top: 15px;
        margin-bottom: 8px;
    }

    .mutlii-drop .multiselect-dropdown .dropdown-btn {
        padding: 8px 18px !important;
    }

    .adjust-time-picker .bs-timepicker-field {
        width: 30px !important;
        font-size: 12px !important;
        padding: 0 !important;
        height: 25px !important;
    }

    .ck-editor__editable_inline {
        min-height: 150px !important;
    }

    .search-list-tabs .nav-tabs .nav-item .nav-link.active,
    .search-list-tabs .nav-tabs .nav-item .nav-link {
        font-size: 12px !important;
    }

    .mobile-detailview-tabs .nav-tabs .nav-item .nav-link span,
    .mobile-detailview-tabs .nav-tabs .nav-item .nav-link.active span {
        font-size: 12px !important;
    }

}

@media (min-width: 768px) {
    /* .center-container-tabs .nav.nav-pills {
        margin-bottom: 8px;
    } */
}

@media (min-width: 768px) and (max-width: 1199.98px) {

    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-item,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-item {
        width: 50%;
    }

    .center-container-tabs .nav-pills .nav-item,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-item {
        width: 33.3%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (max-width: 767.98px) {

    .main-wrapper .panel.card {
        margin-top: 10px !important;
    }

    .news-client-mobilecard .center-container-tabs .nav.nav-pills .nav-item:last-child .nav-link {
        margin-bottom: 0 !important;
    }

    .activity-wrapper .panel {
        margin-top: 8px !important;
    }

    .icon-divs {
        text-align: center;
    }

    .center-container-tabs .nav-pills .nav-item,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-item,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-item,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-item {
        width: 100% !important;
    }

    .center-container-tabs .nav-pills .nav-item:first-child .nav-link,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-item:first-child .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-item:first-child .nav-link,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-item:first-child .nav-link {
        margin-bottom: 8px !important;
    }

    .center-container-tabs .nav-pills .nav-item:nth-child(2) .nav-link,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-item:nth-child(2) .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-item:nth-child(2) .nav-link,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-item:nth-child(2) .nav-link {
        margin-bottom: 8px !important;
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {

    .center-container-tabs .nav-pills .nav-link,
    .market-pulse-mobilecard .center-container-tabs .nav-pills .nav-link,
    .tours-mobilecard .center-container-tabs .nav-pills .nav-link,
    .news-client-mobilecard .center-container-tabs .nav-pills .nav-link {
        margin-right: 0 !important;
        font-size: 14px !important;
    }
}

.multi-select2 .multiselect-dropdown .dropdown-btn .selected-item-container {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    float: none !important;
    display: inline-flex !important;
}

.multi-select2 .multiselect-dropdown .dropdown-btn .selected-item:hover,
.mutlii-drop .multiselect-dropdown .dropdown-btn .selected-item:hover {
    box-shadow: none !important;
}

.mutlii-drop .multiselect-dropdown .dropdown-btn .selected-item-container {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}


.multi-select2 .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: none !important;
    background: none !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    color: #1F549F !important;
}

.mutlii-drop .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: none !important;
    background: none !important;
    max-width: 100% !important;
    color: #6C727F !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.mutlii-drop .multiselect-dropdown .dropdown-list,
.multi-select2 .multiselect-dropdown .dropdown-list {
    border-radius: 15px !important;
}

.multi-select2 .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar,
.mutlii-drop .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar {
    width: 8px;
}

.multi-select2 .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar-thumb,
.mutlii-drop .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar-thumb {
    background-color: #A6CAF5;
    border-radius: 6px;
}

.multi-select2 .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar-track:hover,
.mutlii-drop .multiselect-dropdown .dropdown-list ul::-webkit-scrollbar-track:hover {
    background-color: #e9ebed;
    border-radius: 5px;
    border: 1px solid #D2D5DA;
}

.mutlii-drop .multiselect-dropdown .dropdown-list .item1 {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.office-listing-dropdown .multiselect-dropdown .dropdown-btn .selected-item-container {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.office-listing-dropdown .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: none !important;
    background: none !important;
    max-width: 100% !important;
    color: #6C727F !important;
    overflow: hidden !important;
}

.mutlii-drop .ng-multiselect .multiselect-dropdown .dropdown-btn {
    height: 39.5px !important;
}

.multi-select2 .multiselect-dropdown .dropdown-btn:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A") !important;
    background-position: right 15px top 50% !important;
    background-repeat: no-repeat !important;
}

.multi-select2 .multiselect-dropdown .dropdown-btn {
    color: #1F549F !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    letter-spacing: 0.8px !important;
    border-left: 1px solid #AFAFAF !important;
    border-bottom: none !important;
    border-right: none !important;
    border-top: none !important;
    display: block !important;
    width: 100% !important;
    background: #fff !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
}

.mutlii-drop.dashboard-dropdown .multiselect-dropdown .dropdown-btn {
    font-size: 14px !important;
    padding: 8px 13px !important;
    line-height: 22px !important;
}

.mutlii-drop .multiselect-dropdown .dropdown-btn {
    display: flex !important;
    border: 0.993088px solid #D2D5DA !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 15px !important;
    font-size: 15px;
    font-weight: 500 !important;
    background: #fff !important;
    color: #6C727F !important;
    padding: 6px 13px;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.mutlii-drop .multiselect-item-checkbox input[type=checkbox]:checked+div:before,
.office-listing-dropdown .multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #1f549f !important;
}


.mutlii-drop .multiselect-item-checkbox input[type=checkbox]+div:before,
.office-listing-dropdown .multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #1f549f !important;
    color: #1f549f !important;
}

.multi-select2 .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret,
.multi-select2 .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    display: none !important;
}

.mutlii-drop .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    position: relative;
    right: -2px !important;
    top: 65%;
    color: #1f549f !important;
    margin-top: 4px;
    border-style: solid;
    border-width: 6px 4px 0 4px !important;
    border-color: #1f549f transparent !important;
    content: "";
}

.office-listing-dropdown .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    position: relative;
    right: -2px !important;
    top: 65%;
    color: #1f549f !important;
    margin-top: 4px;
    border-style: solid;
    border-width: 5.6px 5.5px 0 5.5px !important;
    border-color: #1f549f transparent !important;
    content: "";
}

.mylisting-pagination .pagination .page-item.active .page-link {
    color: white;
    background: #1F549F;
    border-color: #1F549F;
    font-weight: 600;
    font-size: 16px;
}

.mylisting-pagination .pagination .page-item .page-link {
    color: #1F549F;
    font-weight: 600;
    font-size: 16px;
}

#Carousel .carousel-indicators li {
    width: 10px !important;
    height: 10px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    border: none !important;
    border-radius: 50% !important;
}

#Carousel .carousel-indicators .active {
    width: 12px !important;
    height: 12px !important;
}

#Carousel .carousel-control-prev,
#Carousel .carousel-control-next {
    display: none;
}

#Carousel2 .carousel-indicators {
    display: none;
}

#Carousel2 .carousel-indicators .active {
    width: 12px !important;
    height: 12px !important;
}

#Carousel2 .carousel-control-prev,
#Carousel2 .carousel-control-next {
    height: 25% !important;
    margin: auto !important;
}

#Carousel2 {
    width: 100% !important;
}

.login-page .custom-control {
    padding-left: 2.3rem !important;
}

.weektabs .nav-tabs .nav-item.show .nav-link,
.weektabs .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #1f549f !important;
}

.availability-tables td {
    border-top: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.theme-green .bs-datepicker-head {
    background-color: #1f549f !important;
}

.btn-link {
    font-weight: 300 !important;
    color: #1f549f !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #1f549f !important;
}

.activity-wrapper .card-header {
    padding: 0 !important;
}

/* .activity-wrapper .panel .panel .panel-heading .panel-title .btn-link {
    overflow: hidden;
    color: #404040 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 14.583px;
} */

.activity-wrapper .panel {
    /* border-radius: 3px 3px 0px 0px; */
    background: #C7DDF8;
    border-radius: 15px;
}

.activity-wrapper .panel .card .card-header {
    border-radius: 15px;
    border-bottom: none;
    background: none;
}

.activity-wrapper .panel .card-body {
    padding: 0;
}

/* .adjust-time-picker .bs-timepicker-field {
    width: 50px;
    font-size: 16px;
    padding: 0;
    height: 28px;
} */

.adjust-time-picker .bs-timepicker-field {
    width: 35px;
    font-size: 14px;
    padding: 0;
    height: 25px;
}

.tooltipClass {
    max-width: 50vw !important;
    background-color: white;
}

.custom-tooltip {
    opacity: 1 !important;
}

.center-container-tabs .nav.nav-pills {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.center-container-tabs .nav-pills .nav-item:last-child .nav-link {
    margin-right: 0 !important;
}

.center-container-tabs .nav-pills .nav-link {
    border-radius: 15px;
    background: #FFF;
    color: #707070;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 15.6px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    padding: 10px 45px 10px 45px;
    border: 1px solid #D2D5DA;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    margin-right: 20px;
}

.center-container-tabs .nav-pills .nav-link:not(.active):hover {
    color: #1F549F;
    background: #F6F9FF;
    border: 1px solid #D2D5DA;
}

.center-container-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #1F549F;
    border-radius: 15px;
    background: #F6F9FF;
    border: 1px solid #D2D5DA;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.center-container-tabs .custom-tab-heading i {
    font-size: 20px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .center-container-tabs .nav-pills .nav-link {
        padding: 8px 25px 8px 25px;
        margin-right: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
    .mutlii-drop .multiselect-dropdown .dropdown-btn {
        font-size: 14px;
        padding: 9px 12px !important;
    }

    .change-text-color-to-grey.adjust-text {
        font-size: 13px;
    }

}




.mobile-detailview-tabs .nav-tabs .nav-item .nav-link span {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: 'inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.3px;
}

.mobile-detailview-tabs .nav-tabs .nav-item .nav-link.active span {
    color: #1F549F;
    font-weight: 700;
    border: none;
}

.mobile-detailview-tabs .nav-tabs .nav-item .nav-link {
    border: none;
}

.mobile-detailview-tabs .nav.nav-tabs .nav-item {
    border-radius: 15px 0 0 0 !important;
}

.mobile-detailview-tabs .nav.nav-tabs .nav-item .nav-link {
    border-radius: 15px 0 0 0 !important;
}

.mobile-detailview-tabs .tab-content {
    margin-top: 2.2rem !important;
}

.mobile-detailview-tabs .nav.nav-tabs {
    position: fixed;
    top: 86px;
    z-index: 1;
    border-radius: 15px 0 0 0 !important;
    border: none !important;
    /* border-bottom: 1px solid #E0E2E7 !important; */
    background: #FFF !important;
    box-shadow: 0px 8px 30px 0px rgba(46, 45, 116, 0.06);
}

.mobile-detailview-tabs {
    border-radius: 15px 15px 0px 0px !important;
    border: 1px solid #E0E2E7 !important;
    background: #FFF !important;
    box-shadow: 0px 8px 30px 0px rgba(46, 45, 116, 0.06) !important;
}







.main-tabs .nav-tabs .nav-item .nav-link span {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: .875rem;
    color: #000;
    border-bottom: .1875rem solid transparent !important;
}

.main-tabs .nav-tabs .nav-item .nav-link.active {
    border-bottom: .1875rem solid #1f549f !important;
}

.main-tabs .nav-tabs .nav-item .nav-link {
    padding-bottom: 0;
    border: none;
    border-bottom: .1875rem solid transparent !important;
}

.main-tabs .subtitle-container .subtitle {
    display: inline;
    font-size: .875rem;
    line-height: 130%;
    font-weight: 200;
    color: #b0b0b0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: .125rem;
}

.main-tabs .subtitle-container {
    display: block;
}

.main-tabs .option {
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 200 !important;
    height: 3.625rem;
    padding-top: 0;
    /* padding-top: .625rem; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 2.5rem);
    color: #000;
}

.main-tabs .multiselect-option:hover {
    background-color: #f3f3f3;
}

.main-tabs .multiselect-option {
    padding: 6px 15px;
    /* padding: 15px; */
}

.main-tabs .multiselect-option,
.main-tabs .multiselect-group {
    list-style: none;
}

.main-tabs .multiselect-options,
.main-tabs .multiselect-group-options {
    padding-left: 0;
}

.main-tabs .no-matches {
    color: #7c7c7c;
    padding: 15px 2px;
}

.main-tabs .group {
    width: 100%;
    display: block;
    padding-top: 15px;
    background-color: #fff;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    padding-left: 1rem;
    margin-bottom: 5px;
    color: #7c7c7c;
    text-transform: uppercase;
}

.showMoreModal-body .main-tabs .nav.nav-tabs {
    display: flex;
    justify-content: center;
    border: none;
}

.showMoreModal-body .main-tabs {
    box-shadow: none;
}

.main-tabs {
    background: #fff;
    box-shadow: 0 .75rem 1rem rgba(0, 0, 0, .15);
    border-radius: .375rem;
    width: 100%;
    padding: 20px;
}

.main-tabs .nav-tabs .nav-item .nav-link:hover {
    border-bottom: .1875rem solid #d2d2d2 !important;
    box-shadow: none;
}


/* Payment Gateways Method css start */


.card-container .card {
    border-radius: 40px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.05) !important;
}

.card-container .card button {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    background: #0AC246;
    border-color: #0AC246;
    padding: 9.5px 0;
    color: white;
    font-weight: 500;
    letter-spacing: 0.5px;
    width: 100% !important;
    border-radius: 15px !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
}

.card-container .card ngx-stripe-card-cvc,
.card-container .card ngx-stripe-card-expiry,
.card-container .card ngx-stripe-card-number {
    border: 0.993088px solid #D2D5DA !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    display: block;
    width: 100%;
    height: 40px !important;
    background: #fff;
    color: #6C727F;
    padding: 10px;
}

.card-container .card input {
    border: 0.993088px solid #D2D5DA !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    display: block;
    width: 100%;
    height: 40px !important;
    padding: 10px !important;
    background: #fff;
    color: #6C727F;
    box-sizing: border-box;
}

ngx-stripe-card-group label {
    color: #6C727F !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 6px !important;
}

.btn-back-full {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25) !important;
    background: #1F549F !important;
    border-color: #1F549F !important;
    padding-left: 9px !important;
    padding-right: 9px !important;
    color: white !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    border-radius: 30px !important;
}

.text-right.text-info {
    color: #20c8e9 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.headings.text-center {
    font-size: 18px !important;
}

.card-details-heading {
    font-size: 18px !important;
}


/* Payment Gateways Method css end */



.tooltip.top .tooltip-arrow {
    border-top-color: #ddd;
}

.tooltip-inner {
    border: 1px solid #ddd;
    /* background-color: #000000; */
    background-color: #ffffff;
    color: #1f549f;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 600;
}

.custom-modal-height {
    top: -3% !important;
}

.ck-editor-custom {
    height: 250px;
}

.ck-editor__editable_inline {
    min-height: 250px;
}

@media (min-width: 1200px) {
    .custom-modal-width.modal-xl {
        --bs-modal-width: 1157px !important;
    }

    .custom-modal-width .modal-dialog {
        max-width: 1157px !important;
    }

    .custom-modal-width .modal-content {
        width: 100%;
    }
}

.modal-content {
    border-radius: 15px !important;
}

.modal-dialog.delete-savedmarket-dialog,
.modal-dialog.delete-savedsearch-dialog {
    max-width: 581px !important;
}

.YearBuilt1 bs-datepicker-container {
    left: 57px !important;
}

.OpenHouse2 bs-datepicker-container {
    left: -57px !important;
}




/* search listing tabs start  */
.search-list-tabs .nav-tabs {
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #E0E2E7;
    box-shadow: 0px 8px 30px 0px rgba(46, 45, 116, 0.06);
    background: #fff;
    padding: 5px;
}

.search-list-tabs .nav-tabs .nav-item .nav-link.active,
.search-list-tabs .nav-tabs .nav-item .nav-link,
.search-list-tabs .nav-tabs .nav-item.active,
.search-list-tabs .nav-tabs .nav-item {
    border: none;
}

.search-list-tabs .tab-container .tab-content {
    padding: 10px;
    background: #fff;
}

.search-list-tabs .nav-tabs .nav-item .nav-link.active {
    color: #1F549F;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.3px;
}

.search-list-tabs .nav-tabs .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.3px;
}

/* search listing tabs start  */










/* google map styling start */

::ng-deep .gm-style-iw .gm-style-iw-c {
    display: none !important;
    overflow: hidden !important;
    box-shadow: none !important;
    border: none !important;
}

::ng-deep .gm-style-iw button.gm-ui-hover-effect {
    display: none !important;
    overflow: hidden !important;
}

::ng-deep .gm-style-iw-chr {
    display: none !important;
    overflow: hidden !important;
}

.only-map.map-container {
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
}


.gm-style-iw-d {
    overflow: visible !important;
    max-height: none !important;
}

.map-info-window {
    padding: 0 !important;
    margin: 0 !important;
}

.map-info-window-card {
    max-width: 200px;
    max-height: 500px;
    overflow: auto;
}

.gm-style-iw {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    max-width: none !important;
}

.gm-style-iw-c {
    overflow: hidden !important;
    box-shadow: none !important;
    border: none !important;
}

::ng-deep map-info-window .gm-style-iw {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
}

::ng-deep map-info-window .gm-style-iw-d {
    padding: 0 !important;
    margin: 0 !important;
}

::ng-deep map-info-window .gm-ui-hover-effect {
    display: none !important;
}

/* map satelite label styling */

.gm-style button,
.gm-control-active {
    font-size: 13px !important;
    padding: 1px 5px !important;
    color: #1f549f !important;
}

/* Target the Labels and Terrain checkbox text */
.gm-style label {
    font-size: 13px !important;
    color: #1f549f !important;
}

/* map satelite label styling */



/* google map styling end */