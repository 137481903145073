@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// .ng-multiselect .multiselect-dropdown .dropdown-btn{
//   padding: 8px 12px!important ;
// }
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: #1f549f;
  --mdc-checkbox-disabled-unselected-icon-color: #1f549f;
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1f549f;
  --mdc-checkbox-selected-hover-icon-color: #1f549f;
  --mdc-checkbox-selected-icon-color: #1f549f;
  --mdc-checkbox-selected-pressed-icon-color: #1f549f;
  --mdc-checkbox-unselected-focus-icon-color: #1f549f;
  --mdc-checkbox-unselected-hover-icon-color: #1f549f;
  --mdc-checkbox-unselected-icon-color: #1f549f;
  --mdc-checkbox-unselected-pressed-icon-color: #1f549f;
  --mdc-checkbox-selected-focus-state-layer-color: transparent;
  --mdc-checkbox-selected-hover-state-layer-color: transparent;
  --mdc-checkbox-selected-pressed-state-layer-color: transparent;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent;
  --mdc-checkbox-unselected-hover-state-layer-color: transparent;
  --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
}

.mdc-checkbox__checkmark {
  width: 80% !important;
  margin: auto !important;
}

.mdc-checkbox__checkmark-path {
  stroke-width: 6.5px !important;
}

.mdc-checkbox__background {
  border-radius: 0 !important;

}

/* session expiration dialog container */
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}

html {
  --mdc-checkbox-state-layer-size: 30px !important;
}

/* You can add global styles to this file, and also import other style files */
html {
  overflow-x: hidden !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

.customAlertClass {
  --mdc-snackbar-container-color: green;
}

.customAlertClass_Error {
  --mdc-snackbar-container-color: maroon;
}

.rounded-chips {
  border-radius: 25px;
}

::ng-deep .mat-mdc-form-field-flex>.mat-mdc-form-field-infix {
  padding: 0.4em 0px !important;
}

::ng-deep .mat-mdc-form-field-label-wrapper {
  top: -1.5em;
}

::ng-deep .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}

.text-ligh {
  color: #FFF;

  text-align: center;
  font-size: 15px;
  font-style: normal;
  // font-weight: 900;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0px;
  background-color: #004F93;
  margin-top: 22px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #ffff !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}